import React, { useState, useEffect, useRef } from 'react';
import { create as am4coreCreate, useTheme as am4coreUseTheme } from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from "@amcharts/amcharts4/core";
import { fetchHosts_campus, fetchMeters_campus, fetchData_csv_campus } from "../routes/api";
import url from '../routes/api';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid, Switch, FormControlLabel, TablePagination } from "@mui/material";

am4core.useTheme(am4themes_animated);

const Parameter_campus = () => {
  const windowHeight = window.innerHeight;
  const [loading, setLoading] = useState(false)
  const [selectedMeter, setSelectedMeter] = useState('');
  const [meters, setMeters] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [parameters, setParameters] = useState([]);

  const [isLoadingHosts, setIsLoadingHosts] = useState(false);
  const [isLoadingMeters, setIsLoadingMeters] = useState(false);
  const [device_id, setDevice_id] = useState(null);
  const [client_id, setClient_id] = useState(null);
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [selectedColumnsY, setSelectedColumnsY] = useState([]);
  const [selectedColumnsYName, setSelectedColumnsYName] = useState([]);
  const [selectedColumnX, setSelectedColumnX] = useState(0); // Column index for x-axis
  const [selectedColumnY, setSelectedColumnY] = useState([]); // Default column indices for y-axis
  const [SelectedDeviceName2, setSelectedDeviceName] = useState("");
  const [SelectedDevice2Name, setSelectedDevice2Name] = useState("");
  const [devices, setDevices] = useState([]);
  const [SelectedHostName2, setSelectedHostName] = useState("");
  const [selectedHost, setSelectedHost] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true); // New state variable for loading screen
  const [selectedDevice, setSelectedDevice] = useState('null');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  const handleColumnYChange = (event) => {
    const deviceName = event.target.options[event.target.selectedIndex].text;
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    const selectedNames = selectedOptions.map((option) => option.getAttribute('name'));
    setSelectedDeviceName(deviceName);
    setSelectedColumnsY(selectedValues);
    setSelectedColumnsYName(selectedNames);
  };

  // console.log("SelectedDeviceName2 for kw", SelectedDeviceName2);
  const deviceNames = SelectedDeviceName2.split(',');

  // Assign values to separate variables
  const variable1 = deviceNames[0];
  const variable2 = deviceNames[1];
  const variable3 = deviceNames[2];
  const variable4 = deviceNames[3];

  const fetchParametersData = async () => {
    try {
      setIsLoadingHosts(true);
      const response = await fetch(`${url}parameters`); // Adjust the API URL if needed
      const data = await response.json();
    setParameters(data.parameters);  // Update to setParameters
    setIsLoadingHosts(false);
  } catch (error) {
    console.error("Error fetching parameters:", error);
    setIsLoadingHosts(false);
  }
};

  useEffect(() => {
    fetchParametersData();  // Invoking the updated function
  }, []);
  
  useEffect(() => {
    const fetchDeviceNames = async () => {
      try {
        const response = await fetch(`${url}get_device`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchDeviceNames();
  }, []);

  const handleDeviceChange = (event) => {
    const selectedDeviceId = event.target.value;
    const device = devices.find(d => d.device_name === selectedDeviceId);
    console.log("Selected Device:", device);

    setSelectedDevice(device);
    setSelectedDevice2Name(device.device_name)

    console.log("Selected Device after setState:", SelectedDevice2Name);

    if (device) {
      setDevice_id(device.device_id);
      setClient_id(device.client_id);
    }
  };


  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading state to true before fetching data
      try {
        const data = await fetchData_csv_campus(client_id, selectedDate, device_id);
        setData(data);
        setLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false if an error occurs
      }
    };
  
    fetchData();
  }, [client_id, selectedDate, device_id,selectedColumnsY]); // Include selectedDevice, selectedDate, and selectedHost in dependency array
  

  useEffect(() => {
    if (data.length > 0) {
      const chart = am4core.create("chart_campus_parameter", am4charts.XYChart);

      // Enable drag-to-zoom
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = 'zoomX'; // Enable zooming horizontally

      const updateChartData = () => {
        const updatedData = data.map((row) => {
          const dateTimeString = row[selectedColumnX];
          const [datePart, timePart] = dateTimeString.split(' ');

          const [hours, minutes] = timePart.split(':');
          const formattedTime = `${hours}:${minutes}`;

          const columnIndices = selectedColumnsY[0].split(',').map((column) => parseInt(column));

          const yValues = columnIndices.map((index) => parseFloat(row[index]));
          // console.log("columnIndices", columnIndices);

          const hasNonNullValue = yValues.some((value) => value !== null && !isNaN(value));

          if (hasNonNullValue) {
            const nonNullValues = yValues.map((value) => (value !== null && !isNaN(value)) ? [value] : null);
            // console.log("the first ", nonNullValues[0]);

            // Print the elements inside columnIndices
            const elements = columnIndices.map((index) => row[index]);
            // console.log("Elements inside columnIndices:", elements);

            return {
              c:  columnIndices,
              x:  formattedTime,
              y:  nonNullValues[0],
              y1: nonNullValues[1],
              y2: nonNullValues[2],
              y3: nonNullValues[3],
            };
          }

          return null;
        }).filter((dataPoint) => dataPoint !== null);

        chart.data = updatedData;
      };

      updateChartData();
      console.log("c", 'c');
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'x';
      categoryAxis.fontFamily = "Comic Sans MS";

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.fontFamily ="Comic Sans MS";

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = 'x';
      series.dataFields.valueY = 'y';
      series.strokeWidth = 2;
      series.minBulletDistance = 30; // Increase the value to increase the distance
      series.tooltipText = "{y}";
      series.fill = am4core.color("#28a745");
      series.stroke = am4core.color("#28a745");
      series.name = variable1;

      const series1 = chart.series.push(new am4charts.LineSeries());
      series1.strokeWidth = 2;
      series1.minBulletDistance = 10;
      series1.dataFields.categoryX = 'x';
      series1.dataFields.valueY = 'y1';
      series1.tooltipText = "{y1}";
      series1.fill = am4core.color("#ef4040");
      series1.stroke = am4core.color("#ef4040");
      series1.name = variable2;

      const series2 = chart.series.push(new am4charts.LineSeries());
      series2.strokeWidth = 2;
      series2.minBulletDistance = 10;
      series2.dataFields.categoryX = 'x';
      series2.dataFields.valueY = 'y2';
      series2.tooltipText = "{y2}";
      series2.fill = am4core.color("#ffc107");
      series2.stroke = am4core.color("#ffc107");
      series2.name = variable3;

      const series3 = chart.series.push(new am4charts.LineSeries());
      series3.strokeWidth = 2;
      series3.minBulletDistance = 10;
      series3.dataFields.categoryX = 'x';
      series3.dataFields.valueY = 'y3';
      series3.tooltipText = "{y3}";
      series3.fontFamily = "Comic Sans MS";
      series3.fill = am4core.color("#008FFB");
      series3.stroke = am4core.color("#008FFB");
      series3.name = variable4;

      const title = chart.titles.create();
      title.text = SelectedDevice2Name + " - "+ selectedDate;
      title.fontSize = 20;
      title.marginBottom = 20;
      title.fontFamily = "Comic Sans MS";

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;
      chart.legend.position = "bottom";
      chart.legend.fontFamily = "Comic Sans MS";

      return () => {
        chart.dispose();
      };
    }
  }, [data, selectedColumnX, selectedColumnsY]);

  return (
    <div>
  <div className="container-fluid mt-1" style={{ fontFamily: 'Comic Sans MS' }}>
        <h5 style={{ textAlign: "left" }}><b>Parameter Graph</b></h5>
      </div>
      <div className="container-fluid d-flex p-2 mt-1 rounded " style={{ backgroundColor: "#a8d7ff" }}>
      <Grid container >
          <Grid >          
            <label htmlFor="columnSelectY" style={{ fontWeight: "bold",   fontFamily: "Comic Sans MS"}}>
            Parameters
          </label>
          <select
            id="columnSelectY"
            value={selectedColumnsY}
            onChange={handleColumnYChange}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              outline: "none",
              marginRight: "15px",
              fontFamily: "Comic Sans MS",
              fontSize: "13px",
              marginLeft: "10px"

            }}
          ><option value="">Select Parameters</option>
          {parameters.map((param, index) => (
            <option key={index} value={param.value}>
              {param.label}
            </option>
          ))}
           </select>
           </Grid>  
        <Grid>
        <div >
        <label htmlFor="select_device" style={{ fontWeight: "bold"}}>
                <strong >Device</strong>
              </label>
                <select
                 style={{
                  padding: "5px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  outline: "none",
                  marginRight: "15px",
                  fontFamily: "Comic Sans MS",
                  fontSize: "13px",
                  marginLeft: "10px"

                }}
                  id="deviceSelect"
                  onChange={handleDeviceChange}
                >
                  <option value="">Select a device...</option>
                  {devices.map(device => (
                    <option key={device.device_name} value={device.device_name}>
                      {device.device_name}
                    </option>
                  ))}
                </select>
              </div>
        </Grid>
        <Grid>

        <div >

          <label htmlFor="datePicker" style={{ fontWeight: "bold" }}>
            <span style={{ fontFamily: "Comic Sans MS"}}>Date</span>
          </label>
          <input
            type="date"
            id="datePicker"
            value={selectedDate}
            onChange={handleDateChange}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              outline: "none",
              fontFamily: "Comic Sans MS",
              fontSize: "13px",
              marginLeft: "10px"
            }}
          />
        </div>
        </Grid>
        </Grid>

      </div>

      <div className=' d-flex mt-2 align-items-center justify-content-center' style={{ position: 'relative', height: windowHeight / 1.55 }}>
        <div style={{
          position: 'absolute',
          opacity: 0.2, // Adjust opacity as needed
          zIndex: 0, // Ensure watermark is behind other content
          backgroundRepeat: 'repeat',
        }}>
          <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Hostel Icon" width='100%' />
        </div>

        {loading && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
            }}
          >
            <div className="spinner-grow text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {!loading && (
          <div
            id="chart_campus_parameter"
            className="container-fluid p-1"
            style={{ height: '100%', backgroundColor: '#d1fec5' }}
          />
        )}
      </div>

    </div>
  );
};

export default Parameter_campus;
