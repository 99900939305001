import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import url from "../routes/api";
import "../style/Studentdetails.css";

const Billsbyname = () => {
  const [studentNames, setStudentNames] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentDetails, setStudentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch student names from the API
  useEffect(() => {
    const fetchStudentNames = async () => {
      try {
        const response = await axios.get(`${url}student-names`);
        setStudentNames(response.data.map(name => ({
          value: name.student_name,
          label: name.student_name
        })));
      } catch (error) {
        console.error('Error fetching student names:', error);
      }
    };

    fetchStudentNames();
  }, []);

  // Fetch student details when a student is selected
  useEffect(() => {
    if (selectedStudent) {
      const fetchStudentDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${url}student-details/${selectedStudent.value}`);
          setStudentDetails(response.data);
          setError(null);
        } catch (error) {
          console.error('Error fetching student details:', error);
          setError('Failed to fetch student details.');
        } finally {
          setLoading(false);
        }
      };

      fetchStudentDetails();
    }
  }, [selectedStudent]);

  return (
    <div className="container">
      <h1>Student Details</h1>
      <div className="dropdown-container">
        {/* <h2>Students Device Details Report</h2> */}
        <Select
          options={studentNames}
          onChange={setSelectedStudent}
          placeholder="Select a student name"
          styles={{
            container: (provided) => ({
              ...provided,
              width: 300
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: 9999
            })
          }}
        />
      </div>
      <div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {studentDetails.length > 0 && (
          <table className="details-table">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Hostel</th>
                <th>Student ID</th>
                <th>Device ID</th>
                <th>Phase</th>
                <th>Room No</th>
                <th>Branch</th>
                <th>Phone No</th>
                <th>Address</th>
                <th>Email Id</th>
              </tr>
            </thead>
            <tbody>
              {studentDetails.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.student_name}</td>
                  <td>{detail.hostel_id}</td>
                  <td>{detail.student_id}</td>
                  <td>{detail.device_id}</td>
                  <td>{detail.phase}</td>
                  <td>{detail.room_no}</td>
                  <td>{detail.branch_id}</td>
                  <td>{detail.phone_no}</td>
                  <td>{detail.stud_address}</td>
                  <td>{detail.email_id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {studentDetails.length === 0 && !loading && !error && <p>No details available</p>}
      </div>
    </div>
  );
};

export default Billsbyname;
