import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { fetchHostel, fetchData_bar, hostel_graph } from "../routes/api";
import '../style/hostel_graph.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Grid, Switch, FormControlLabel, TablePagination } from "@mui/material";


am4core.useTheme(am4themes_animated);

const Hostel_graph = () => {
  const windowHeight = window.innerHeight;


  const [hosts, setHosts] = useState([]);

  const [selectedMeter, setSelectedMeter] = useState('');
  const [meters, setMeters] = useState([]);
  const [loading,setLoading] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(true); // New state variable for loading screen


  const [isLoadingHosts, setIsLoadingHosts] = useState(false);
  const [isLoadingMeters, setIsLoadingMeters] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [selectedHost, setSelectedHost] = useState("durga");
  const [SelectedDeviceName2, setSelectedDeviceName] = useState("");
  const [selectedHostName, setSelectedHostName] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoadingHosts(true);
        const hostsData = await fetchHostel();
        setHosts(hostsData);
        setIsLoadingHosts(false);
      } catch (error) {
        console.error("Error fetching hosts:", error);
        setIsLoadingHosts(false);
      }
    };

    fetchInitialData();
  }, []);



  const onDeviceChange = (event) => {
    const deviceName = event.target.options[event.target.selectedIndex].text;
    const device = event.target.value;
    setSelectedDeviceName(deviceName);
    setSelectedDevice(device);
  };

  // Sort function to sort data based on dt_time
  const sortByTime = (data) => {
    return data.slice().sort((a, b) => a.dt_time.localeCompare(b.dt_time));
  }


  // Fetch data from Node.js API and set it to chartData state variable
  useEffect(() => {
    // setIsLoadingData(true);
    setLoading(true);
    hostel_graph(selectedHost, selectedDate)
      .then((data) => {
        const chartDataWithTime = data.map((dataPoint) => ({
          dt_time: new Date(dataPoint.dt_time).toLocaleTimeString([], {
            hour: "2-digit",
            hour12: false,
          }),
          hostel_id: dataPoint.hostel_id,
          date: new Date(dataPoint.dt_time).toLocaleDateString(),
          sum_ryb: dataPoint.sum_ryb,
          sum_total: dataPoint.sum_total,
          common_area: dataPoint.common_area,
          hour: dataPoint.hour,
        }));

        const sortedChartData = sortByTime(chartDataWithTime); // Sort the data by dt_time

        setChartData(sortedChartData);
        // setIsLoadingData(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoadingData(false);
      });
  }, [selectedHost, selectedDate]);



  useEffect(() => {
    console.log("chartData:", chartData); // Log chartData to check its contents
  console.log("chartData length:", chartData.length); 
    if (!loading && chartData.length > 0) { // Check if chartData has been loaded
      const chart = am4core.create("chartdiv", am4charts.XYChart);

      chart.data = chartData;


      // Create X axis
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      // Configure category axis
      categoryAxis.dataFields.category = "dt_time";
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
      categoryAxis.renderer.labels.template.truncate = true;
      categoryAxis.renderer.labels.template.maxWidth = 120;
      categoryAxis.renderer.labels.template.fontSize = 14;
      categoryAxis.fontFamily = "Comic Sans MS";

      // Create Y axis
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;
      //  valueAxis.title.text = "Values";
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.fontFamily = "Comic Sans MS";

      // Create series for "sum_ryb" column
      const seriesRyb = chart.series.push(new am4charts.ColumnSeries());
      seriesRyb.dataFields.valueY = "sum_ryb";
      seriesRyb.dataFields.categoryX = "dt_time";
      // seriesRyb.strokeWidth = 2;
      seriesRyb.minBulletDistance = 10;
      seriesRyb.tooltipText = "Room Kwh: {sum_ryb}";
      seriesRyb.name = "Room Kwh";
      seriesRyb.columns.template.fill = am4core.color("#98FB98"); // Blue
      seriesRyb.columns.template.stroke = am4core.color("#98FB98");
      seriesRyb.stacked = true;

      // Create series for "common_area" column
      const seriesCommon = chart.series.push(new am4charts.ColumnSeries());
      seriesCommon.dataFields.valueY = "common_area";
      seriesCommon.dataFields.categoryX = "dt_time";
      seriesCommon.strokeWidth = 2;
      seriesCommon.minBulletDistance = 10;
      seriesCommon.tooltipText = "Common Area: {common_area}";
      seriesCommon.name = "Common Area";
      seriesCommon.columns.template.fill = am4core.color("#F4A460"); // Red
      seriesCommon.columns.template.stroke = am4core.color("#F4A460");
      seriesCommon.stacked = true;


      // Create series for "sum_total" column
      const seriesTotal = chart.series.push(new am4charts.ColumnSeries());
      seriesTotal.dataFields.valueY = "sum_total";
      seriesTotal.dataFields.categoryX = "dt_time";
      seriesTotal.strokeWidth = 2;
      seriesTotal.minBulletDistance = 10;
      seriesTotal.tooltipText = "Net Kwh: {sum_total}";
      seriesTotal.name = "Hostel Kwh";
      seriesTotal.columns.template.fill = am4core.color("#2E4053"); // Green
      seriesTotal.columns.template.stroke = am4core.color("#2E4053");
      seriesTotal.columns.template.fillOpacity = 1; // Adjust the opacity value as needed
      seriesTotal.columns.template.strokeOpacity = 1; // Adjust the opacity value as needed
      seriesTotal.stacked = false;



      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";
      chart.legend.fontFamily = "Comic Sans MS";


      // Add chart cursor
      chart.cursor = new am4charts.XYCursor();

      const HostelName = chartData.length > 0 ? chartData[0].hostel_id : '';
      const Date = chartData.length > 0 && chartData[0].date !== undefined ? chartData[0].date : '';
  
      console.log("the hostel id is- - ", HostelName, Date);
  
      // Add title
      const title = chart.titles.create();
      title.text = `${HostelName} - ${Date}`;
      title.fontSize = 20;
      title.marginBottom = 20;
      title.fontFamily = "Comic Sans MS";

      return () => {
        chart.dispose();
      };
    }
  }, [chartData, loading, selectedDate, selectedHostName, SelectedDeviceName2]);


  const handleHostChange = (event) => {
    const selectedOption = event.target.value;
    const selectedOptionName = event.target.options[event.target.selectedIndex].text;

    setSelectedHost(selectedOption);
    setSelectedMeter('');
    setSelectedHostName(selectedOptionName);
  };

  const handleMeterChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedMeter(selectedOption);
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };


  return (
    <div>
      <div className="container-fluid mt-1" style={{ fontFamily: 'Comic Sans MS' }}>
        <h5 style={{ textAlign: "left" }}><b>Hostel Consumption</b></h5>
      </div>
      <div className="container-fluid d-flex p-2 mt-1 rounded " style={{ backgroundColor: "#a8d7ff" }}>
        <Grid container >
          <Grid md={4}>
            <div >
              <label htmlFor="select_host" >
                <strong>Select Hostel:</strong>
              </label>

              <select
             
                id="select_host"
                value={selectedHost}
                onChange={handleHostChange}
                style={{
                  padding: '5px',
                  borderRadius: '5px',
                
                  fontFamily: 'Comic Sans MS',
                  fontSize: '14px',
                 
                  marginLeft: '15px',
                }}
              >
                {/* <option value="">Select Host</option> */}
                {isLoadingHosts ? (
                  <option value="" disabled>Loading hosts...</option>
                ) : (
                  hosts.map(host => (
                    <option key={host.client_id} value={host.client_id}>
                      {host.hostel_id}
                    </option>
                  ))
                )}
              </select>

            </div>
          </Grid>
          <Grid md={4}>
          <div >
            <label htmlFor="datePicker" style={{ marginRight: "10px", fontWeight: "bold"}}>
              <span style={{ fontFamily: "Comic Sans MS"}}>Select Date</span>
            </label>

            <input
              type="date"
              id="datePicker"
              value={selectedDate}
              onChange={handleDateChange}
              style={{
                padding: "5px",
                borderRadius: "5px",
             
                fontFamily: "Comic Sans MS",
                fontSize: "14px",
                marginLeft: '15px',
              }}
            />
          </div>
          </Grid>
        </Grid>
      </div>
      <div className=' d-flex mt-2 align-items-center justify-content-center' style={{ position: 'relative', height: windowHeight / 1.5 }}>
      {/* Watermark */}
      <div style={{   position: 'absolute',
      opacity: 0.2, // Adjust opacity as needed
      zIndex: 0, // Ensure watermark is behind other content
      backgroundRepeat: 'repeat',
    }}>
      <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Hostel Icon" width= '100%'/>
      </div>

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
        >
          <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && (
        <div
          id="chartdiv"
          className="container-fluid p-1"
          style={{ height: '100%', backgroundColor: '#d1fec5' }}
        />
      )}
    </div>
      </div>
   

  );
};

export default Hostel_graph;