import React, { useState, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'bootstrap/dist/css/bootstrap.min.css';
import url from '../routes/api';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const BarGraph_Hourly = (props) => {
  const [chartData, setChartData] = useState([]);
  const windowHeight = window.innerHeight
  const [loading, setLoading] = useState(false)

  // if()
  useEffect(() => {
    const fetchDataAndCreateChart = async () => {
      try {
        setLoading(true);
        let decodedSwitchName;
        if (props.device_id >= 0 && props.device_id != null) {
          decodedSwitchName = decodeURIComponent(props.switchName);
          console.log("client_id",props.client_id)
          console.log("device_id",props.device_id)
        } else {
          console.log("client_id else",props.client_id)
          console.log("device_id else",props.device_id)
          decodedSwitchName = null;
        }        
        console.log('decodedSwitchName',decodedSwitchName)
        const response = await fetch(`${url}hourly_graph/${decodedSwitchName}/${props.date}/${props.client_id}/${props.device_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setChartData(data);
        setLoading(false);

      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchDataAndCreateChart();
  }, [props.client_id, props.device_id, props.switchName, props.date]);

  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('bar_graph_hourly', am4charts.XYChart);

    chart.logo.disabled = true;

    // Add data
    chart.data = chartData;

    const DeviceName = chartData.length > 0 && chartData[0].device_name !== undefined ? chartData[0].device_name : '';
    const DateName = chartData.length > 0 && chartData[0].date !== undefined ? chartData[0].date : '';

    let title = chart.titles.create();
    title.text = `${DeviceName} - ${DateName}`;
    title.fontSize = 14;
    title.marginBottom = 20;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'hour';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = 'left';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.tooltip.disabled = true;

    chart.cursor = new am4charts.XYCursor();

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = 'wh_R';
    series1.dataFields.categoryX = 'hour';
    series1.name = 'Reveiced';
    series1.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series1.stroke = am4core.color('#ff4646'); // Set the line color
    series1.fill = am4core.color('#ff4646'); // Set the fill color
    series1.fillOpacity = .8;
    series1.tensionX = 0.8; // Set tension for smoothness
    series1.yAxis = valueAxis;
    series1.strokeWidth = 3; // Increase line width

    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = 'wh_D';
    series2.dataFields.categoryX = 'hour';
    series2.name = 'Delivered';
    series2.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series2.stroke = am4core.color('#6c82ff'); // Set the line color
    series2.fill = am4core.color('#6c82ff'); // Set the fill color
    series2.fillOpacity = .8;
    series2.tensionX = 0.8; // Set tension for smoothness
    series2.yAxis = valueAxis;
    series2.strokeWidth = 3; // Increase line width

    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = 'net';
    series3.dataFields.categoryX = 'hour';
    series3.name = 'Net';
    series3.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series3.stroke = am4core.color('#202246'); // Set the line color
    series3.fill = am4core.color('#202246'); // Set the fill color
    series3.fillOpacity = .8;
    series3.tensionX = 0.8; // Set tension for smoothness
    series3.yAxis = valueAxis;
    series3.strokeWidth = 3; // Increase line width

    // Add fade effect to the end of the line
    series1.segments.template.endCapDisabled = false;
    series1.segments.template.endCapCornerRadius = 10; // Adjust corner radius for fade effect
    series1.segments.template.adapter.add("fillOpacity", function (fillOpacity, target) {
      return (1 - Math.abs(target.dataItem.valueY / valueAxis.maxZoomed)) * fillOpacity;
    });

    // Add fade effect to the end of the line
    series2.segments.template.endCapDisabled = false;
    series2.segments.template.endCapCornerRadius = 10; // Adjust corner radius for fade effect
    series2.segments.template.adapter.add("fillOpacity", function (fillOpacity, target) {
      return (1 - Math.abs(target.dataItem.valueY / valueAxis.maxZoomed)) * fillOpacity;
    });

    // Add fade effect to the end of the line
    series3.segments.template.endCapDisabled = false;
    series3.segments.template.endCapCornerRadius = 10; // Adjust corner radius for fade effect
    series3.segments.template.adapter.add("fillOpacity", function (fillOpacity, target) {
      return (1 - Math.abs(target.dataItem.valueY / valueAxis.maxZoomed)) * fillOpacity;
    });

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fontSize = 10; // Set legend item font size
    chart.legend.markers.template.width = 10; // Set legend marker width
    chart.legend.markers.template.height = 10; // Set legend marker height

    // Calculate min and max values for y-axis
    let minValue = Infinity;
    let maxValue = -Infinity;
    chart.data.forEach(item => {
      minValue = Math.min(minValue, item.wh_R, item.wh_D, item.net);
      maxValue = Math.max(maxValue, item.wh_R, item.wh_D, item.net);
    });
    valueAxis.min = minValue;
    valueAxis.max = maxValue;

    return () => {
      chart.dispose();
    };
  }, [chartData]);




  return (
    <div className=' d-flex align-items-center justify-content-center' style={{ position: 'relative', height: windowHeight / 2 }}>
      {/* Watermark */}
      <div style={{
        position: 'absolute',
        opacity: 0.2, // Adjust opacity as needed
        zIndex: 0, // Ensure watermark is behind other content
        backgroundRepeat: 'repeat',
      }}>
        <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Hostel Icon" width='100%' />
      </div>

      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
        >
          <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && (
        <div
          id="bar_graph_hourly"
          className="container-fluid p-1"
          style={{ height: '100%', backgroundColor: '#d1fec5' }}
        />
      )}
    </div>);
};

export default BarGraph_Hourly;
