import React, { useState, useEffect } from 'react';
import { loginapi2, decryptPassword } from '../routes/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './main.js';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import '../style/Login.css';


const Login_page = () => {
    const windowHeight = window.innerHeight;
    const [userType, setUserType] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [apiData, setApiData] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsButtonDisabled(!e.target.value);
        setErrorMessage('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const loginResponse = await loginapi2(email, password);

            if (loginResponse && loginResponse.message === 'Login successful') {
                setIsLoggedIn(true);
                setUserType(loginResponse.user_type);

            } else {
                setErrorMessage('Invalid email or password');
            }
        } catch (error) {
            console.error('API request failed:', error);
            setErrorMessage('Invalid email or password');
        }
    };


    useEffect(() => {
        if (isLoggedIn) {
            // Perform successful login logic here
            console.log('Successful login');

            // Set a timeout to redirect to login after 1 hour (3600000 milliseconds)
            const timeoutId = setTimeout(() => {
                setIsLoggedIn(false);  // Set login status to false after 1 hour
            }, 3600000);

            // Clear the timeout if the component unmounts or user logs out manually
            return () => clearTimeout(timeoutId);
        }
    }, [isLoggedIn]);

    console.log(userType);

    if (isLoggedIn) {
        return (
            <div>
               <Main userType={userType} login_status={setIsLoggedIn} />

            </div>
        );
    }

    return (
        <div className='bg-dark d-flex justify-content-center align-items-center login-container' style={{ height: windowHeight }}>
            <div className='bg-white d-flex flex-column m-3 justify-content-center align-items-center p-3 rounded' style={{ boxShadow: "2px solid black" }}>
                <img src={`${process.env.PUBLIC_URL}/icons/JNMC_LOGO.png`} alt="Logo" class="img-fluid" className='p-2' />
                <h4 class='text-center'>Datta Meghe Institute  </h4>
                <h4 class='text-center'> of Higher Education</h4>
                <h4 class='text-center'> Research</h4>
                <form onSubmit={handleSubmit} className="form1">

                    <TextField className='m-2 '
                        id="outlined"
                        type="email"
                        color="success"
                        label="Email"
                        value={email}
                        onChange={handleEmailChange}
                    />


                    <TextField className='m-2'
                        id="outlined"
                        type="password"
                        label="Password"
                        color="success"
                        value={password} onChange={handlePasswordChange}
                    />
                   

                    <button type="submit" class="btn mb-3 p-2 text-white" style={{ backgroundColor: "#6c82ff" }} >
                        Log In
                    </button>
                    
                    {errorMessage && (
                        <div className="error-container">
                            <p className="error-message">{errorMessage}</p>
                        </div>
                    )}
                    <p className='h6 mt-2' style={{ fontFamily: "Comic Sans MS" }}>Energy Montoring System</p>
                    <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Logo Right" class="img-fluid" style={{ width: "15vh", alignSelf: "center" }} />
                </form>

            </div>
            <div className="sticky-text text-white d-flex justify-content-center align-items-center"
                style={{ height: "45px", backgroundColor: "#6c82ff" }}>
                <p className='p-0 m-0'> © 2024 Copyright: conceptualized, Designed, Installed & Maintained By <strong>Heta Datain</strong></p>
            </div>
        </div>
    )
}

export default Login_page