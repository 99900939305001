import { React, useState } from "react";
import { Grid, Switch, FormControlLabel, TablePagination } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css'
import Year_chart from "./year";
import '../style/home.css'
import Piechart from "./pie";
import BarGraph_Daily from "./bar_graph_daily";
import BarGraph_Hourly from "./bar_graph_hourly";
import BarGraph_yearly from "./bar_graph_yearly";
import { useEffect } from "react";
import url from "../routes/api";


const Home_page = () => {
  const [client_id, setClient_id] = useState(null);
  const [month_year, setMomth_Year] = useState(formatDate(new Date()));
  const [hourly_date, sethourly_date] = useState(formatDate(new Date()));
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('null');
  const windowHeight = window.innerHeight;
  const [device_id, setDevice_id] = useState(null);
  const [date, setdate] = useState(formatDate(new Date()))
  const [lastUpdatedDate, setLastUpdatedDate] = useState('date');

  const [values, setValues] = useState({
    transformer: true,
    solar: false,
    hostel: false,
    others: false
  });

  const handleClientIDChange = (id) => {
    setClient_id(id);
  };

  const handleDeviceIDChange = (id) => {
    setDevice_id(id);
  }

  const handlemonth_year = (date) => {
    setMomth_Year(date)
    setdate(date)
    sethourly_date(date)
  }

  const handle_hourly_date = (date) => {
    sethourly_date(date);
  }

  // Function to format date as yyyy-mm-dd
  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const handledatechange = (event) => {
    setdate(event.target.value)
    setMomth_Year(event.target.value)
    sethourly_date(event.target.value)

    console.log(event.target.value)

    if (!values.others) {
      setClient_id('null');
      setDevice_id('null');
    }
  }

  const handleChange = (name) => () => {
    setClient_id(null);
    setDevice_id(null);

    setValues((prevValues) => ({
      ...Object.keys(prevValues).reduce((acc, key) => {
        acc[key] = key === name;
        return acc;
      }, {})
    }));
    console.log(`${name} switch is ON`);
  };

  useEffect(() => {
    const fetchDeviceNames = async () => {
      try {
        const response = await fetch(`${url}get_device`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchDeviceNames();
  }, []);

  const handleDeviceChange = (event) => {
    const selectedDeviceId = event.target.value;
    const device = devices.find(d => d.device_name === selectedDeviceId);
    console.log("Selected Device:", device);

    setSelectedDevice(device);
    console.log("Selected Device after setState:", selectedDevice);

    if (device) {
      setDevice_id(device.device_id);
      setClient_id(device.client_id);
    }
  };


  return (
    <div className="mb-3">
      <div className="container-fluid mt-1" style={{ fontFamily: 'Comic Sans MS' }}>
        <h5 style={{ textAlign: "left",color:"#6723a6" }}><b> Dashboard</b></h5>
      </div>
      <div className="container-fluid rounded mb-2" style={{ backgroundColor: "#a8d7ff" }}>
        <Grid container className="p-1">
          <Grid md={2} xs={6} >
            <FormControlLabel
              control={<Switch checked={values.transformer} onChange={handleChange('transformer')} />}
              label="Transformer"
              labelPlacement="start"
            />
          </Grid>
          <Grid md={1} xs={6} >
            <FormControlLabel
              control={<Switch checked={values.solar} onChange={handleChange('solar')} color="warning" />}
              label="Solar"
              labelPlacement="start"
            />
          </Grid> 
          <Grid md={2} xs={6} >
            <FormControlLabel
              control={<Switch checked={values.hostel} onChange={handleChange('hostel')} />}
              label="Hostel"
              labelPlacement="start"
            />
          </Grid>
          <Grid md={2} xs={6} className="container-fluid ">
            <input type="date" value={date} onChange={handledatechange} className="form-control" />
          </Grid>

          <Grid md={5} xs={12} className="container-fluid d-md-flex align-items-center justify-content-between bg-white rounded">
            <FormControlLabel
              control={<Switch checked={values.others} onChange={handleChange('others')} color="default" />}
              label="Others"
              labelPlacement="start"
            />

            {values.others && (
              <div className="container-fluid p-1">
                <select
                  style={{
                    fontSize: "14px",
                    borderRadius: '5px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                  id="deviceSelect"
                  onChange={handleDeviceChange}
                >
                  <option value="">Select a device...</option>
                  {devices.map(device => (
                    <option key={device.device_name} value={device.device_name}>
                      {device.device_name}
                    </option>
                  ))}
                </select>
              </div>
            )}


          </Grid>
         

        </Grid>



      </div>

      <div style={{ maxHeight: windowHeight / 1.5, overflowY: 'auto' }} >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="bg-white graph_bg container-3d rounded-3 ">
              <Piechart onClientIDChange={handleClientIDChange}
                onDeviceIDChange={handleDeviceIDChange}
                switchName={values.transformer ? 'transformer' : values.solar ? 'solar' : values.hostel ? 'hostel' : values.others ? selectedDevice.Category : ''}
                date={date} />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="bg-white graph_bg container-3d rounded-3">
              <BarGraph_yearly
                onMonth_YearChange={handlemonth_year}
                client_id={client_id}
                device_id={device_id}
                switchName={values.transformer ? 'transformer' : values.solar ? 'solar' : values.hostel ? 'hostel' : values.others ? selectedDevice.Category : ''}
                date={date} />

            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="bg-white  graph_bg container-3d rounded-3 ">
              <BarGraph_Hourly
                client_id={client_id}
                device_id={device_id}
                switchName={values.transformer ? 'transformer' : values.solar ? 'solar' : values.hostel ? 'hostel' : values.others ? selectedDevice.Category : ''}
                date={hourly_date} />

            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="bg-white graph_bg container-3d rounded-3 ">
              <BarGraph_Daily
                onhour_date_change={handle_hourly_date}
                client_id={client_id}
                device_id={device_id}
                switchName={values.transformer ? 'transformer' : values.solar ? 'solar' : values.hostel ? 'hostel' : values.others ? selectedDevice.Category : ''}
                date={month_year} />

            </div>

          </Grid>

        </Grid>
      </div>
    </div>
  );


};
export default Home_page;