import logo from './logo.svg';
import './App.css';
import React from 'react';
import Main from './components/main';
import Sidebar from './components/sidebar';
import Home_page from './components/home';
import Demo from './components/demo'
import Login_page from './components/login';
function App() {
  return (
    <div className="App">
      {/* <Main /> */}
      <Login_page/>
    </div>
  );
}
 

export default App;
