import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Demo() {
  const windowHeight = window.innerHeight;

  return (
    <div className="container-fluid p-0" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="row" style={{ flex: 1 }}>
        <div className="col" style={{ backgroundColor: 'lightblue', height: '100%' }}>
          <h2>Top Div</h2>
        </div>
      </div>
      <div className="row" style={{ maxHeight: windowHeight / 1.5, overflowY: 'auto' }}>
        <div className="col" style={{ backgroundColor: 'lightgreen', height: '100%' }}>
          <h2>Bottom Div (Scrollable)</h2>
          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          <p>Scroll me! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit lorem vel posuere rutrum.</p>

          
          {/* Add more content if needed */}
        </div>
      </div>
    </div>
  );
}
export default Demo;
