import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'bootstrap';
import '../style/main.css' 
import url from '../routes/api';

const BiReports = () => {
    const windowHeight = window.innerHeight;
    const [images,setImages] = useState([])

    const biReports = async () => {
        try {
            const response = await fetch(`${url}bi`);
            if (!response.ok) {
                throw new Error('Failed to fetch reports');
            }
            const data = await response.json();
            setImages(data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    };

    useEffect(() => {
        biReports();
    }, []);



  // Initialize the carousel when the component mounts
  React.useEffect(() => {
    const carouselElement = document.getElementById('demo');
    const carousel = new Carousel(carouselElement, {
      interval: 2000, // Set the interval between slides (in milliseconds)
      keyboard: true, // Enable keyboard navigation
    });

    return () => {
      // Clean up the carousel when the component unmounts
      carousel.dispose();
    };
  }, []); // Empty dependency array to run this effect only once when the component mounts

  return (
    <div id="demo" className="carousel slide" data-bs-ride="carousel" >
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-current={index === 0 ? 'true' : 'false'}
            aria-label={`Slide ${index}`}
            style={{color:'black'}}
          />
        ))}
      </div>

      <div className="carousel-inner" >
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img src={image.src} className='container-fluid' style={{maxHeight: windowHeight/1.3}} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>

      <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default BiReports;
