import React from "react";

const Graph_page = () => {
    return(
        <div>
            this is the Graph Page 
        </div>
    )
}

export default Graph_page;