import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse'; // Import PapaParse for CSV conversion
import url from "../routes/api";

const StudentsNameBill = () => {
  const [hostelId, setHostelId] = useState('');
  const [date, setDate] = useState('');
  const [reportData, setReportData] = useState([]);
  const [hostels, setHostels] = useState([]);
  const [error, setError] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const maxRetries = 3;

  useEffect(() => {
    const fetchHostels = async () => {
      try {
        const response = await axios.get(`${url}hostel`);
        console.log('Hostels fetched:', response.data);

        if (Array.isArray(response.data) && response.data.every(item => item.hasOwnProperty('hostel_id'))) {
          setHostels(response.data.map(item => ({
            id: item.hostel_id,
            name: item.hostel_id // Adjust if needed
          })));
          setError(''); // Clear any previous errors
        } else {
          throw new Error('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching hostels:', error);
        setError('Failed to fetch hostels. Please try again.');

        if (retryCount < maxRetries) {
          setRetryCount(retryCount + 1);
          setTimeout(fetchHostels, 2000); // Retry after 2 seconds
        }
      }
    };

    fetchHostels();
  }, [retryCount]);

  useEffect(() => {
    const fetchReportData = async () => {
      if (date && hostelId) {
        try {
          const response = await fetch(`${url}report?date=${date}&hostel_id=${hostelId}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();

          // Ensure the month_year field is set correctly for each item
          const dataWithMonthYear = data.map(item => ({
            ...item,
            month_year: date // Add selected mm-yyyy value
          }));

          console.log('Report data fetched:', dataWithMonthYear);
          setReportData(dataWithMonthYear);
        } catch (error) {
          console.error('Error fetching report data:', error);
        }
      }
    };

    fetchReportData();
  }, [date, hostelId]); // Fetch data whenever date or hostelId changes

  const handleDownload = () => {
    if (!reportData || reportData.length === 0) return;
  
    // Calculate totals
    const totalSum = reportData.reduce((total, item) => total + item.sum, 0);
    const totalCacharge = reportData.reduce((total, item) => total + item.cacharge, 0);
    const totalAmount = reportData.reduce((total, item) => total + item.total_amount, 0);
    const totalUnits = reportData.reduce((total, item) => total + item.units, 0); // Total units
  
    // Create a total row with the same order as reportData
    const totalRow = {
      month_year: 'Total',
      student_id: '',
      student_name: '',
      branch_id: '',
      hostel_id: '',
      room_no: '',
      units: totalUnits, // Include total units
      sum: totalSum,
      cacharge: totalCacharge,
      total_amount: totalAmount
    };
  
    // Append total row to reportData
    const dataWithTotal = [...reportData, totalRow];
  
    // Convert data to CSV
    const csv = Papa.unparse(dataWithTotal, {
      header: true, // Ensures header is included
      columns: [
        'month_year',
        'student_id',
        'student_name',
        'branch_id',
        'hostel_id',
        'room_no',
        'units',
        'sum',
        'cacharge',
        'total_amount'
      ]
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `monthly_billing_report_${date}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const years = Array.from({ length: 18 }, (_, i) => 2024 + i); // 2024-2040

  // Calculate totals
  const totalSum = reportData.reduce((total, item) => total + item.sum, 0);
  const totalCacharge = reportData.reduce((total, item) => total + item.cacharge, 0);
  const totalAmount = reportData.reduce((total, item) => total + item.total_amount, 0);

  return (
    <div>
      <h2>Monthly Billing Report</h2> 
      <form onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="hostel_id">Hostel ID:</label>
          <select
            id="hostel_id"
            value={hostelId}
            onChange={(e) => setHostelId(e.target.value)}
            required
          >
            <option value="">Select Hostel</option>
            {hostels.length > 0 ? (
              hostels.map((hostel) => (
                <option key={hostel.id} value={hostel.id}>
                  {hostel.name}
                </option>
              ))
            ) : (
              <option value="">No hostels available</option>
            )}
          </select>
        </div>

        <div>
          <label htmlFor="date">Month-Year:</label>
          <select
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          >
            <option value="">Select Month</option>
            {years.flatMap((year) =>
              Array.from({ length: 12 }, (_, i) => {
                const month = (i + 1).toString().padStart(2, '0');
                return (
                  <option key={`${month}-${year}`} value={`${month}-${year}`}>
                    {`${month}-${year}`}
                  </option>
                );
              })
            )}
          </select>
        </div>

        <button type="button" onClick={() => setDate(date)}>Submit</button>
      </form>

      {reportData.length > 0 && (
        <div>
          <h2>Monthly Billing Report</h2>
          <button onClick={handleDownload}>Download CSV</button> {/* Download button */}
          <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Month-Year</th>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>Branch ID</th>
                <th>Hostel ID</th>
                <th>Room No</th>
                <th>Units</th>
                <th>Kwh Sum</th>
                <th>CA Charge</th>
                <th>Total Bill Amount</th>
              </tr>
            </thead>
            <tbody>
              {reportData.map((item) => (
                <tr key={item.student_id}>
                  <td>{item.month_year}</td>
                  <td>{item.student_id}</td>
                  <td>{item.student_name}</td>
                  <td>{item.branch_id}</td>
                  <td>{item.hostel_id}</td>
                  <td>{item.room_no}</td>
                  <td>{item.units}</td>
                  <td>{item.sum}</td>
                  <td>{item.cacharge}</td>
                  <td>{item.total_amount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="7"><strong>Total</strong></td>
                <td><strong>{totalSum}</strong></td>
                <td><strong>{totalCacharge}</strong></td>
                <td><strong>{totalAmount}</strong></td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {error && (
        <div>
          <p>{error}</p>
          <button onClick={() => setRetryCount(0)}>Retry Fetching Hostels</button>
        </div>
      )}
    </div>
  );
};

export default StudentsNameBill;
