import axios from "axios";

//  const url = 'http://127.0.0.1:8000/api/'

const url = 'https://sawangi.hetadatain.com/api/'

export default url;

export const fetchMeters = async (host) => {
  try {
    const response = await fetch(`${url}device?client_id=${host}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch meters");
  }
};



export const meterstatus = async (client, date) => {
  try {
    const apiUrl =fetch( `${url}/current_status/${client}/${date}`);
    const response = await fetch(apiUrl);
    const jsonData = await response.json();
    console.log("API Response:", jsonData); // Add this line to check the complete API response
    return jsonData.distinct_data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
export const chiller_csv = async (date) => {
  try {
    const formattedDate = date.split("-").reverse().join("-");

    const response = await fetch(
      `${url}chiller-data/CHILLER-AVBRH/${formattedDate}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
export const fetchMeters_campus = async (host) => {
  try {
    const response = await fetch(`${url}device_campus?client_id=${host}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch meters");
  }
};

export const fetchData_bar = async (hostId, deviceId, date) => {
  try {
    const response = await fetch(
      `${url}graph/${date}/${hostId}/${deviceId}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
export const fetchHosts = async () => {
  try {
    const response = await fetch(`${url}host`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch hosts");
  }
};

export const fetchHosts_campus = async () => {
  try {
    const response = await fetch(`${url}host_campus`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch hosts");
  }
};


export const fetchData_csv = async (hostId, date, deviceId ) => {
  try {
    const formattedDate = date.split("-").reverse().join("-");

    const response = await fetch(
      `${url}csv-data/${hostId}/${formattedDate}/${deviceId}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const fetchData_csv_campus = async (hostId, date, deviceId ) => {
  try {
    const formattedDate = date.split("-").reverse().join("-");

    const response = await fetch(
      `${url}csv-data-campus/${hostId}/${formattedDate}/${deviceId}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const fetchHostel = async () => {
    try {
      const response = await fetch(`${url}hostel`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Failed to fetch hosts");
    }
  };

  export const hostel_graph = async (hostId, date) => {
    try {
      const response = await fetch(
        // `${url}/hostel_consumption?client_id=${hostId}&date=${date}`
        `${url}hostel_hourly_graph/${date}/${hostId}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Failed to fetch data");
    }
  };

  export const fetchRooms = async (room) => {
    try {
      const response = await fetch(`${url}room?room_no=${room}`);
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Failed to fetch meters");
    }
  };

  export const loginapi2 = async (email, password) => {
    try {
      const response = await axios.post(`${url}loginnew`, {
        email: email,
        password: password,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };