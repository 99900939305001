import React, { useState, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'bootstrap/dist/css/bootstrap.min.css'
import BarGraph_Daily from './bar_graph_daily';
import url from '../routes/api';
import { Bar } from 'recharts';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const PieChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [loading,setLoading] = useState(false)
  const windowHeight = window.innerHeight;

  useEffect(() => {
    fetchData();
  }, [props.switchName,props.date]); // Fetch data whenever switchName changes

  const fetchData = async () => {
    try {
      setLoading(true);
      const decodedSwitchName = decodeURIComponent(props.switchName);
      const response = await fetch(`${url}get_daily_pie/${decodedSwitchName}/${props.date}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setChartData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };



  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('chartdiv', am4charts.PieChart);

    // Hide amCharts logo
    chart.logo.disabled = true;

    // Add data
    chart.data = chartData;

    // Add and configure series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'net'; // Set the value field to 'net'
    pieSeries.dataFields.category = 'device_name';

    // Set font size for category labels
    pieSeries.labels.template.fontSize = 12; // Adjust the font size as needed

    pieSeries.labels.template.disabled = true;

    // Add legends
  // Add legend
  chart.legend = new am4charts.Legend();
  chart.legend.position = 'right'; // Position legend on the right side
  chart.legend.labels.template.text = ' {name}: [bold]{value.value}[/]'; // Display actual value instead of percentage
  chart.legend.labels.template.fontSize = 11; // Adjust the font size as needed
  chart.legend.valueLabels.template.disabled = true; // Hide the percentage
  chart.legend.markers.template.width = 10; // Set legend marker width
   chart.legend.markers.template.height = 10; // Set legend marker height
   chart.legend.itemContainers.template.paddingTop = 0;
   chart.legend.itemContainers.template.paddingLeft = 0;
   chart.legend.itemContainers.template.paddingBottom = '0.5px';



    // Position labels on the right side
    pieSeries.labels.template.align = 'right';
    pieSeries.labels.template.position = 'end';

    pieSeries.innerRadius = am4core.percent(60); // Adjust inner radius as needed

    pieSeries.slices.template.cornerRadius = 3; // Adjust border radius as needed

    // Configure labels to show net value instead of percentage
    pieSeries.labels.template.text = '{category}: {value}';

    const DateName = chartData.length > 0 && chartData[0].date !== undefined ? chartData[0].date : '';

    let title = chart.titles.create();
    title.text = ` ${DateName}`;
    title.fontSize = 14;
    // title.fontWeight = 'bold'
    title.marginBottom = 20;

    // Add event listener for slice selection
    pieSeries.slices.template.events.on("hit", (event) => {
      const sliceData = event.target.dataItem.dataContext;
      props.onClientIDChange(sliceData.client_id);
      props.onDeviceIDChange(sliceData.device_id)
    });
  
    return () => {
      chart.dispose();
    };
  }, [chartData]);



  return  (
    <div className=' d-flex align-items-center justify-content-center' style={{ position: 'relative', height: windowHeight / 2 }}>
    {/* Watermark */}
    <div style={{   position: 'absolute',
    opacity: 0.2, // Adjust opacity as needed
    zIndex: 0, // Ensure watermark is behind other content
    backgroundRepeat: 'repeat',
  }}>
    <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Hostel Icon" width= '100%'/>
    </div>

    {loading && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
        }}
      >
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )}

    {!loading && (
      <div
        id="chartdiv"
        className="container-fluid p-1"
        style={{ height: '100%', backgroundColor: '#d1fec5' }}
      />
    )}
  </div>);};

export default PieChart;
