import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  SpeedDial,
  SpeedDialAction,
  IconButton,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Home,
  DeviceHubOutlined,AttachMoneyOutlined,ReceiptOutlined,HelpOutline,
  RoomOutlined,AddCircleOutline,
  AcUnitOutlined,settings,
  HomeOutlined,
  ElectricMeterOutlined,
  DevicesOutlined,
  AutoGraphOutlined,
  PersonSearchOutlined,
  BarChartOutlined,
  StackedLineChart,
  AdminPanelSettings,
  GraphicEqOutlined,
  GridView,
  ReportOutlined,
  AccountBalance,
  Description,
  Logout,
  Settings,
} from "@mui/icons-material";
import Home_page from "./home";
import Graph_page from "./graph";
import Hostel_graph from "./hostel_graph";
import Monthly_bill from "./Monthly_bill";
import Piechart from "./pie";
import "../style/main.css";
import { Dropdown } from "react-bootstrap";
import Dashboard from "./dashboard";
// import Reports from "./reports";
import Reportnew from "./reportnew";
import Report_Biller from "./report_biller";
import Bi_reports from "./bi_reports";
import Bi_links from "./bi_links";
import Bar_graph from "./bar_graph";
import Parameter_biller from "./parameter_biller";
import Parameter_campus from "./parameter_campus";
import Wardhastatus from "./wardhastatus";
import Chiller_report from "./Chiller_report";
import Empty_room_report from "./empty_room_report";
import Chiller from "./Chiller";
import SortName from "./SortName";
// import Devicestatus from "./devicestatus";
import App from "./App";
import RoomTable from "./RoomTable";
import Billsbyname from "./Billsbyname";
import StudentsNameBill from "./StudentsNameBill";
import Monthlycount from "./Monthlycount";
import updatehourlykwh from "./updatehourlykwh";



const Main = (props) => {
  const { userType } = props;
  const { login_status } = props;
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [lastInteractionTime, setLastInteractionTime] = useState(new Date());

  const handleLogout = () => {
    if (typeof login_status === 'function') {
      login_status(false);
    }
  };

  useEffect(() => {
    const logoutTimeout = setTimeout(() => {
      const currentTime = new Date();
      const inactiveDuration = currentTime.getTime() - lastInteractionTime.getTime();
      const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds

      if (inactiveDuration >= tenMinutes) {
        handleLogout(); // Logout user if inactive for 10 minutes
      }
    }, 1000); // Check every second

    return () => clearTimeout(logoutTimeout); // Clean up timer
  }, [lastInteractionTime]); // Re-run effect when lastInteractionTime changes

  // Function to update last interaction time
  const updateLastInteractionTime = () => {
    setLastInteractionTime(new Date());
  };

  return (
    <Router>
      <div style={{ fontFamily: "Comic Sans MS" }}>
        <div className="text-center d-flex">
          <div className="d-flex align-items-start">
            <div
              className="d-none d-sm-block"
              style={{ height: "100%", borderRight: "3px solid #c1c1c13b" }}
            >
              <ul className="list-group list-group-flush p-2">
                <div className="container-fluid d-flex mt-3 align-items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/icons/log.png`}
                    alt="Hostel Icon"
                    width={120}
                  />
                </div>
                <button
                  onClick={handleLogout}
                  className="list-group-item m-2 bg-transparent rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Logout />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Logout</span>
                  </div>
                </button>
                <div className="mt-2"></div>
                <p
                  className="text ml-3 m-0 mt-2 text-dark title"
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  <b>Campus</b>
                </p>
                <Link
                  to="/home"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <HomeOutlined />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Home</span>
                  </div>
                </Link>
                <Link
                  to="/bi"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <GridView />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;BI</span>
                  </div>
                </Link>
                {userType === "admin"|| userType === 'sadmin'  && (
                  <Link
                    to="/bi_dashboard"
                    className="list-group-item bg-transparent border-0 rounded"
                  >
                    <div className="container-fluid d-flex align-items-center">
                      <AdminPanelSettings />
                      <span className="ml-2">
                        &nbsp;&nbsp;&nbsp;&nbsp;Admin
                      </span>
                    </div>
                  </Link>
                )}
                <Link
                  to="/parameter_campus"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <StackedLineChart />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Parameter
                    </span>
                  </div>
                </Link>
                <Link
                  to="/Chiller_report"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <AcUnitOutlined />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Chiller
                    </span>
                  </div>
                </Link>
                <Link
                  to="/Chiller"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <GraphicEqOutlined />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Chiller Graph
                    </span>
                  </div>
                </Link>
                
                
                {/* {userType === "admin" && (
                  <Link
                  to="/updatehourlykwh"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Settings />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp; Update hourly kwh
                    </span>
                  </div>
                </Link>
                 )}  */}
                 {/* <Link
                  to="/devicestatus"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <DeviceHubOutlined /> {/* Add DeviceHubOutlined icon */}
                     {/* <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Device Status */}
                    {/* </span> */}
                  {/* </div>  */}
                {/* </Link>  */}

                <Link
                  to="/SortName"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Description />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Reports
                    </span>
                  </div>
                </Link>
                <hr
                  className="mt-1"
                  style={{
                    width: "50%",
                    alignSelf: "center",
                    color: "#c1c1c13b",
                  }}
                ></hr>
                <p
                  className="text ml-3 m-0 text-dark title"
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  <b>Biller</b>
                </p>
                <Link
                  to="/biller_cards"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <HomeOutlined />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Home</span>
                  </div>
                </Link>
                <Link
                  to="/hourly_graph"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <BarChartOutlined />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Hourly</span>
                  </div>
                </Link>
                <Link
                  to="/parameter_biller"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <StackedLineChart />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Parameter
                    </span>
                  </div>
                </Link>
                <Link
                  to="/reports_biller"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Description />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Reports
                    </span>
                  </div>
                </Link>
                <Link
                  to="/hostel_hourly_graph"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <AutoGraphOutlined />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;CA</span>
                  </div>
                </Link>
                <Link
                  to="/wardhastatus"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <ElectricMeterOutlined />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;MeterStatus
                    </span>
                  </div>
                </Link>
                <Link
                  to="/empty_room_report"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <RoomOutlined />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;EmptyRoomReport
                    </span>
                  </div>
                </Link>
                
                <Link
                  to="/StudentsNameBill"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <AddCircleOutline />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;StudentMonthlyBill</span>
                  </div>
                </Link>

                <Link
                  to="/monthy_bill"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <AccountBalance />
                    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Bill</span>
                  </div>
                </Link>
                <Link
  to="/Monthlycount"
  className="list-group-item bg-transparent border-0 rounded"
>
  <div className="container-fluid d-flex align-items-center">
    <HelpOutline />
    <span className="ml-2">
      &nbsp;&nbsp;&nbsp;&nbsp;MonthlyStudentCount
    </span>
  </div>
</Link>

                <Link to="/App" className='list-group-item bg-transparent border-0 rounded'> {/* Add new link */}
                                    <div className='container-fluid d-flex align-items-center'>
                                        <AttachMoneyOutlined />
                                        <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Student Bill</span> {/* New icon and label */}
                                    </div>
                                </Link>
                                <Link
  to="/Billsbyname"
  className="list-group-item bg-transparent border-0 rounded"
>
  <div className="container-fluid d-flex align-items-center">
    <ReceiptOutlined />
    <span className="ml-2">&nbsp;&nbsp;&nbsp;&nbsp;Student Details</span>
  </div>
</Link>


                                <Link
                  to="/RoomTable"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <DevicesOutlined /> {/* New icon added */}
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Device Details
                    </span>
                  </div>
                </Link>
                {/* {userType === "sadmin" && ( */}
                  <Link
                  to="/updatehourlykwh"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Settings />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp; Update hourly kwh
                    </span>
                  </div>
                </Link>
                  {/* )} */}

                <a
                  href="https://sawangibillernewstudent.hetadatain.com"
                  target="_blank"
                  className="list-group-item bg-transparent border-0 rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <PersonSearchOutlined />
                    <span className="ml-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;Students
                    </span>
                  </div>
                </a>
                <hr
                  className="mt-1"
                  style={{ width: "50%", alignSelf: "center" }}
                ></hr>
                {/* <button
                  onClick={handleLogout}
                  className="list-group-item m-2 bg-transparent rounded"
                >
                  <div className="container-fluid d-flex align-items-center">
                    <Logout />
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;Logout</span>
                  </div>
                </button> */}
              </ul>
            </div>
          </div>
          <div className="container-fluid p-2">
            <div className="container-fluid">
              <img
                src={`${process.env.PUBLIC_URL}/icons/JNMC_LOGO.png`}
                alt="Hostel Icon"
                width={40}
              />
              <h5>&nbsp;&nbsp;&nbsp;&nbsp;Sawangi Campus</h5>
              <Typography
                variant="body1"
                style={{
                  marginLeft: "auto",
                  color: "#263c5c",
                  fontSize: "14px",
                  fontFamily: "Comic Sans MS",
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons/calendar.png`}
                  style={{ color: "#263c5c" }}
                  alt="Hostel Icon"
                  width={15}
                />{" "}
                {currentDateTime.toLocaleDateString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}{" "}
                {currentDateTime.toLocaleTimeString("en-US")}
              </Typography>
            </div>
            <div className="main-border d-xs-block d-sm-none d-md-none d-lg-none d-flex justify-content-center align-items-center mt-2">
              <div className="container-fluid d-flex m-3 align-items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/icons/log.png`}
                  alt="Hostel Icon"
                  width={120}
                />
              </div>
              <div className="container">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Menu
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/home">
                      Home
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/bi">
                      Bi Report
                    </Dropdown.Item>
                    {/* {userType === "admin"|| userType === 'sadmin'  && ( */}
                      <Dropdown.Item as={Link} to="/bi_dashboard">
                        Admin
                      </Dropdown.Item>
                    {/* )} */}
                  
                    <Dropdown.Item as={Link} to="/parameter_campus">
                      Parameter Campus
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/reports">
                      Reports
                    </Dropdown.Item>
                    {/* <Dropdown.Item as={Link} to="/devicestatus">
                      Meter Status
                    </Dropdown.Item> */}
                    <Dropdown.Item as={Link} to="/biller_cards">
                      Biller
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/hourly_graph">
                      Hourly Graph
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/parameter_biller">
                      Parameter Biller
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/reports_biller">
                      Biller Reports
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/hostel_hourly_graph">
                      Hostel CA
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/monthy_bill">
                      Bill
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/wardhastatus">
                      Meter Status
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Chiller">
                      Chiller Graph
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/empty_room_report">
                    Empty Room Report
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/App">
                      Student bill
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Billsbyname">
                      Billing Report
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/RoomTable">
                      Device Details
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/StudentsNameBill">
                      Students monthly bill
                    </Dropdown.Item>
                    {/* {userType === "sadmin"  && ( */}
                    <Dropdown.Item as={Link} to="/updatehourlykwh">
                      Update hourly kwh
                    </Dropdown.Item>
                    {/* )} */}

                    <Dropdown.Item as={Link} to="/Monthlycount">
                      Monthly Student Count
                    </Dropdown.Item>
                    <a
                      href="https://sawangibillernewstudent.hetadatain.com"
                      style={{ marginLeft: "15px", color: "black" }}
                      target="_blank"
                    >
                      Student Record
                    </a>
                    <div className="d-flex justify-content-center mt-2">
                      <button
                        type="button"
                        className="btn btn-danger align-self-center"
                        onClick={handleLogout}
                      >
                        Log out
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="container-fluid p-1 ml-2">
              <Switch>
                <Route path="/home" component={Home_page} />
                <Route path="/bi" component={Bi_reports} />
                <Route path="/bi_dashboard" component={Bi_links} />
                <Route path="/graph" component={Graph_page} />
                <Route path="/hostel_hourly_graph" component={Hostel_graph} />
                <Route path="/monthy_bill" component={Monthly_bill} />
                <Route path="/biller_cards" component={Dashboard} />
                {/* <Route path="/reports" component={Reportnew} /> */}
                <Route path="/reports_biller" component={Report_Biller} />
                <Route path="/hourly_graph" component={Bar_graph} />
                <Route path="/parameter_biller" component={Parameter_biller} />
                <Route path="/parameter_campus" component={Parameter_campus} />
                <Route path="/wardhastatus" component={Wardhastatus} />
                <Route path="/Chiller" component={Chiller} />
                <Route path="/RoomTable" component={RoomTable} />
                {/* <Route path="/devicestatus" component={Devicestatus} /> */}
                <Route
                  path="/empty_room_report"
                  component={Empty_room_report}
                />
                <Route path="/Chiller_report" component={Chiller_report} />
                <Route path="/SortName" component={SortName} />{" "}
                <Route path="/App" component={App} />
                <Route path="/Billsbyname" component={Billsbyname} />
                <Route path="/updatehourlykwh" component={updatehourlykwh} />
                <Route path="/Monthlycount" component={Monthlycount} />
                <Route path="/StudentsNameBill" component={StudentsNameBill} />
                {/* Route for Campus ReportOld */}
                <Route component={Home_page} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sticky-text text-white d-flex justify-content-center align-items-center"
        style={{ height: "45px", backgroundColor: "#6c82ff" }}
      >
        <p className="p-0 m-0">
          © 2024 Copyright: conceptualized, Designed, Installed & Maintained By{" "}
          <strong>Heta Datain</strong>
        </p>
      </div>
    </Router>
  );
};

export default Main;
