import React, { useState, useEffect } from 'react';
import axios from 'axios';
import url from "../routes/api";
import "../style/Monthlycount.css"; // Import the CSS file

const Monthlycount = () => {
    const [monthYear, setMonthYear] = useState('');
    const [reportData, setReportData] = useState([]);

    // Generate month-year options from 2024 to 2030
    const generateMonthYearOptions = () => {
        const options = [];
        for (let year = 2024; year <= 2030; year++) {
            for (let month = 1; month <= 12; month++) {
                const formattedMonth = month.toString().padStart(2, '0');
                options.push(`${formattedMonth}-${year}`);
            }
        }
        return options;
    };

    const handleChange = (event) => {
        setMonthYear(event.target.value);
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('default', { month: 'short' });
    };

    const formatMonthYear = (monthYear) => {
        const [month, year] = monthYear.split('-');
        return `${getMonthName(parseInt(month))}-${year}`;
    };

    const fetchReport = async () => {
        if (monthYear) {
            try {
                const response = await axios.get(`${url}monthly-report`, {
                    params: { date: monthYear }
                });

                // Add formatted month-year column to each row in the report data
                const formattedData = response.data.map(item => ({
                    ...item,
                    month_year: formatMonthYear(monthYear)
                }));
                setReportData(formattedData);
            } catch (error) {
                console.error('Error fetching report:', error.response?.data || error.message);
            }
        }
    };

    const downloadCSV = () => {
        const headers = ['Month-Year', 'Hostel ID', 'Total Room Count', 'Occupied Rooms throughout the month', 'Student Count', 'Vacant Rooms'];
        const rows = reportData.map(data => [
            data.month_year,
            data.hostel_id,
            data.total_room_count,
            data.room_count,
            data.student_count,
            data.room_count_difference
        ]);

        const csvContent = [
            'Billed Student Count Report', // Add report heading
            '',
            headers.join(','), // header row
            ...rows.map(row => row.join(',')) // data rows
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report-${monthYear}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchReport();
    }, [monthYear]);

    return (
        <div>
            <h2>Billed Student Count Report</h2>
            <div style={{ display: "flex", marginBottom: "10px", marginTop: "2vh", marginLeft: "2vw" }}>
                <div style={{ width: '15vw', alignItems: "center", marginRight: "10px", backgroundColor: "rgb(156 152 255)", padding: "5px", borderRadius: "10px" }}>
                    <label htmlFor="month-year" style={{ marginRight: '10px', fontWeight: 'bold', fontFamily: 'Comic Sans MS', color: "#ffffff" }}>
                        Select Month-Year: 
                    </label>
                    <select
                        id="month-year"
                        value={monthYear}
                        onChange={handleChange}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            outline: "none",
                            fontFamily: "Comic Sans MS",
                            fontSize: "14px",
                            minWidth: "120px", // Adjust width as needed
                        }}
                    >
                        <option value="">--Select Month-Year--</option>
                        {generateMonthYearOptions().map((option, index) => (
                            <option key={index} value={option}>
                                {formatMonthYear(option)}
                            </option>
                        ))}
                    </select>
                </div>
                {reportData.length > 0 && (
                    <button
                        onClick={downloadCSV}
                        style={{
                            padding: "10px 20px", // Increased padding for larger button
                            borderRadius: "8px", // Larger border-radius for a bigger button
                            backgroundColor: "rgb(156 152 255)",
                            color: "#fff",
                            fontWeight: "bold",
                            fontFamily: "Comic Sans MS",
                            border: "none",
                            cursor: "pointer",
                            marginLeft: "10px",
                            alignSelf: "center",
                            fontSize: "16px" // Increased font size
                        }}
                    >
                        Download CSV
                    </button>
                )}
            </div>
            {reportData.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            <th>Month-Year</th>
                            <th>Hostel ID</th>
                            <th>Total Room Count</th>
                            <th>Occupied Rooms throughout the month</th>
                            <th>Student Count</th>
                            <th>Vacant Rooms</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.month_year}</td>
                                <td>{data.hostel_id}</td>
                                <td>{data.total_room_count}</td>
                                <td>{data.room_count}</td>
                                <td>{data.student_count}</td>
                                <td>{data.room_count_difference}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Monthlycount;
