

import React, { useState, useEffect } from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

import url from "../routes/api";
const Report_Biller = () => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API endpoint
    axios
      .get(`${url}billing-reports`)
      .then((response) => {
        // Upon successful response, extract options from the data
        const data = response.data;
        const optionList = Object.keys(data).map((key) => ({
          name: key,
          url: data[key],
        }));
        // Set options and mark loading as false
        setOptions(optionList);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle errors
      });
  }, []);

  const handleDownload = () => {
    if (selectedOption) {
      // Navigate the current window to the selected URL
      window.location.href = selectedOption.url;
    }
  };

  const handleSelectChange = (event) => {
    const selectedUrl = event.target.value;
    const selected = options.find((option) => option.url === selectedUrl);
    setSelectedOption(selected);
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center" style={{ height: '60vh' }} >


      <div style={{ backgroundColor: '#d1fec5' }} className="p-3 rounded" >

        <h4 style={{ fontFamily: "Comic Sans MS" }} className="container-fluid mb-2">Select a Report</h4>
        <select
          className="dropdown-select form-control mt-3"
          onChange={handleSelectChange}
        >
            <option value="">Select a Report</option>

          {loading ? (
            <option>Loading...</option>
          ) : (
            options.map((option) => (
              <option key={option.name} value={option.url}>
                {option.name}
              </option>
            ))
          )}
        </select>
        <button
          style={{ backgroundColor: "#6c82ff", color: "#fff" }} // Set color to contrast with the background
          className="dropdown-button btn btn-white mt-3 bg-none"
          onClick={handleDownload}
          disabled={!selectedOption}
        >
          Download
        </button>

      </div>
    </div>
  );
}

export default Report_Biller;
