import React, { useState, useEffect } from 'react';
import axios from 'axios';
import url from "../routes/api";

const RoomTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hostelIdFilter, setHostelIdFilter] = useState('');
  const [roomNoFilter, setRoomNoFilter] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}rooms`);
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleHostelIdChange = (e) => {
    const value = capitalizeFirstLetter(e.target.value);
    setHostelIdFilter(value);
    filterData(value, roomNoFilter);
  };

  const handleRoomNoChange = (e) => {
    const value = capitalizeFirstLetter(e.target.value);
    setRoomNoFilter(value);
    filterData(hostelIdFilter, value);
  };

  const filterData = (hostelId, roomNo) => {
    const filtered = data.filter(item =>
      (hostelId === '' || item.hostel_id.toString().includes(hostelId)) &&
      (roomNo === '' || item.room_no.toString().includes(roomNo))
    );
    setFilteredData(filtered);
  };

  return (
    <div>
      <div>
        <label>
          Hostel ID:
          <input
            type="text"
            value={hostelIdFilter}
            onChange={handleHostelIdChange}
          />
        </label>
        <label>
          Room No:
          <input
            type="text"
            value={roomNoFilter}
            onChange={handleRoomNoChange}
          />
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>Hostel ID</th>
            <th>Room No</th>
            <th>Client Id</th>
            <th>Device Id</th>
            <th>Phase</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(item => (
            <tr key={item.room_id}>
              <td>{item.hostel_id}</td>
              <td>{item.room_no}</td>
              <td>{item.client_id}</td>
              <td>{item.device_id}</td>
              <td>{item.phase}</td> {/* Replace with actual columns */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RoomTable;
