import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/Apps.css'; // Ensure the correct path and file name

const App = () => {
  const [studentId, setStudentId] = useState('');
  const [year, setYear] = useState('');
  const [studentIds, setStudentIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bills, setBills] = useState([]);
  const [billsLoading, setBillsLoading] = useState(false);
  const [billsError, setBillsError] = useState(null);

  useEffect(() => {
    axios
      .get('https://sawangi.hetadatain.com/api/distinct-student-ids')
      .then((response) => {
        setStudentIds(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBillsLoading(true);
    setBillsError(null);
    try {
      const url = `https://sawangi.hetadatain.com/api/studentyear_bill/${studentId}/${year}`;
      const response = await axios.get(url);
      setBills(response.data);
      setBillsLoading(false);
    } catch (error) {
      setBillsError(error);
      setBillsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setStudentId(e.target.value);
  };

  const handleSelectChange = (e) => {
    setStudentId(e.target.value);
  };

  const filteredStudentIds = studentIds.filter((id) =>
    id.toLowerCase().includes(studentId.toLowerCase())
  );

  const yearSuggestions = Array.from({ length: 18 }, (_, index) => 2023 + index);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Student ID:
          <input
            type="text"
            value={studentId}
            onChange={handleInputChange}
            list="studentIds"
          />
          <datalist id="studentIds">
            {filteredStudentIds.map((id) => (
              <option key={id} value={id} />
            ))}
          </datalist>
        </label>
        <label>
          Year:
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            list="yearSuggestions"
          />
          <datalist id="yearSuggestions">
            {yearSuggestions.map((yr) => (
              <option key={yr} value={yr} />
            ))}
          </datalist>
        </label>
        <button type="submit">Get Yearly Bill</button>
      </form>

      {billsLoading && <p>Loading bills...</p>}
      {billsError && <p>Error loading bills: {billsError.message}</p>}

      {bills.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Month</th>
              <th>Hostel</th>
              <th>Room No</th>
              <th>Units</th>
              <th>Room bill Amount</th>
              <th>CA Bill Amount</th>
              <th>Total Bill Amount</th>
               
            </tr>
          </thead>
          <tbody>
            {bills.map((bill, index) => (
              <tr key={index}>
                <td>{bill.month_name}</td>
                <td>{bill.hostel_id}</td>
              
                <td>{bill.room_no}</td>
                <td>{bill.units}</td>
                <td>{bill.sum}</td>
                <td>{bill.cacharge}</td>
                <td>{bill.total_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default App;
