import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import url from "../routes/api"; // Adjust the import path as needed
import "../style/SortName.css";

const SortName = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerateReport = async () => {
    if (startDate && endDate && reportType) {
      setLoading(true); // Set loading to true while fetching data

      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

      let apiUrl;
      let fileName;
      if (reportType === "energy") {
        apiUrl = `${url}export-energy-reports/${formattedStartDate}/${formattedEndDate}`;
        fileName = "energy_report.xlsx";
      } else if (reportType === "transformer") {
        apiUrl = `${url}export/${formattedStartDate}/${formattedEndDate}`;
        fileName = "transformer_report.xlsx";
      } else if (reportType === "solar") {
        apiUrl = `${url}solar-e/${formattedStartDate}/${formattedEndDate}`;
        fileName = "solar_report.xlsx";
      } else if (reportType === "hostel") {
        apiUrl = `${url}hostelExport/${formattedStartDate}/${formattedEndDate}`;
        fileName = "hostel_report.xlsx";
      } else if (reportType === "pumpFlow") {
        apiUrl = `${url}pumpflowExport/${formattedStartDate}/${formattedEndDate}`;
        fileName = "pump_flow_report.xlsx";
      } else if (reportType === "pump") {
        // Added pump report type
        apiUrl = `${url}pumpExport/${formattedStartDate}/${formattedEndDate}`;
        fileName = "pump_report.xlsx";
      }

      try {
        const response = await axios.get(apiUrl, { responseType: "blob" });
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error generating report. Please try again.");
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    } else {
      alert("Please select a report type and both start and end dates.");
    }
  };

  return (
    <div className="sort-name-container">
      <h2 style={{ fontFamily: '"Comic Sans MS", cursive, sans-serif' }}>
        Report Generator
      </h2>
      <div className="controls-container">
        <div className="report-type-dropdown">
          <label className="comic-sans">Select Report </label>
          <select
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <option value="" disabled>
              Select report
            </option>
            <option
              value="energy"
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Energy Report
            </option>
            <option
              value="transformer"
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Transformer Report
            </option>
            <option
              value="solar"
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Solar Report
            </option>
            <option
              value="hostel"
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Hostel Report
            </option>
            <option
              value="pumpFlow"
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Pump Flow Report
            </option>
            <option
              value="pump" // Added pump report type option
              style={{
                fontFamily: '"Comic Sans MS", cursive, sans-serif',
                fontSize: "17px",
              }}
            >
              Pump Report
            </option>
          </select>
        </div>
        <div className="date-picker-container">
          <div
            className="date-picker"
            style={{
              fontFamily: '"Comic Sans MS", cursive, sans-serif',
              fontSize: "17px",
            }}
          >
            <label className="comic-sans">Select Start Date </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select start date"
              dateFormat="MM/dd/yyyy"
              className="form-control"
            />
          </div>
          <div
            className="date-picker"
            style={{
              fontFamily: '"Comic Sans MS", cursive, sans-serif',
              fontSize: "17px",
            }}
          >
            <label className="comic-sans">Select End Date </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="Select end date"
              dateFormat="MM/dd/yyyy"
              className="form-control"
            />
          </div>
        </div>
      </div>
      <button className="generate-report-button" onClick={handleGenerateReport}>
        Generate Report
      </button>
      {loading && (
        <div className="loading-indicator">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default SortName;
