import React, { useState } from 'react';
import axios from 'axios';

const UpdateHourlyKwh = () => {
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [roomNo, setRoomNo] = useState('');
    const [hostelId, setHostelId] = useState('');
    const [whValue, setWhValue] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Determine which WH value to update based on current values
        const requestBody = {
            date,
            hour,
            device_id: deviceId,
            room_no: roomNo,
            hostel_id: hostelId,
            wh_1: whValue || null,
            wh_2: whValue || null,
            wh_3: whValue || null
        };

        try {
            const response = await axios.post('https://sawangi.hetadatain.com/api/update-hourly-kwh', requestBody);
            setMessage(response.data.message);
        } catch (error) {
            console.error(error);
            setMessage('An error occurred');
        }
    };

    return (
        <div>
            <h1>Update Hourly KWh</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Hour:</label>
                    <input
                        type="text"
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Device ID:</label>
                    <input
                        type="text"
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Room No:</label>
                    <input
                        type="text"
                        value={roomNo}
                        onChange={(e) => setRoomNo(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Hostel ID:</label>
                    <input
                        type="text"
                        value={hostelId}
                        onChange={(e) => setHostelId(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>WH Value:</label>
                    <input
                        type="number"
                        value={whValue}
                        onChange={(e) => setWhValue(e.target.value)}
                    />
                </div>
                <button type="submit">Update</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default UpdateHourlyKwh;
