import React, { useState, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import 'bootstrap/dist/css/bootstrap.min.css';
import url from '../routes/api';

// Apply amCharts themes
am4core.useTheme(am4themes_animated);

const BarGraph_yearly = (props) => {
  const [chartData, setChartData] = useState([]);
  const [loading,setLoading] = useState(false)
    const windowHeight = window.innerHeight



  useEffect(() => {
    fetchData();
    // console.log('client_id in bar_graph', props.client_id);
    // console.log('device_id in bar_graph', props.device_id)
  }, [props.client_id, props.device_id,props.switchName,props.date]); // Fetch data whenever client_id or device_id changes

  const fetchData = async () => {
    try {
      setLoading(true);
      const decodedSwitchName = decodeURIComponent(props.switchName);
      const response = await fetch(`${url}get_yearly_bar/${decodedSwitchName}/${props.date}/${props.client_id}/${props.device_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setChartData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('bar_graph_yearly', am4charts.XYChart);

    // console.log('this is creating yearly graph')


    chart.logo.disabled = true;

    // Add data
    chart.data = chartData;
    
    const DeviceName = chartData.length > 0 && chartData[0].device_name !== undefined ? chartData[0].device_name : '';
    // console.log('Device Name:', DeviceName);

    
    let title = chart.titles.create();
    title.text = `${DeviceName}`;
    title.fontSize = 14;
    title.marginBottom = 20;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'month';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = 'left';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.tooltip.disabled = true;


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

   // Create line series instead of column series
   let series1 = chart.series.push(new am4charts.LineSeries());
   series1.dataFields.valueY = 'wh_r';
   series1.dataFields.categoryX = 'month';
   series1.name = 'Reveiced';
   series1.tooltipText = '{categoryX}: [bold]{valueY}[/]';
   series1.fill = am4core.color('#ff4646'); // Set the fill color
   series1.stroke = am4core.color('#ff4646'); // Set the stroke color for the line
   series1.tensionX = 0.8; // Adjust tension for smoothness
   series1.strokeWidth = 3; // Increase line width
   series1.yAxis = valueAxis;



   // Handle click events on line series
   series1.events.on("hit", function(ev) {
     const columnData = ev.target.dataItem.dataContext;
     props.onMonth_YearChange(columnData.date);
     // console.log(columnData.date); // You can access the associated data here
   });

   let series2 = chart.series.push(new am4charts.LineSeries());
   series2.dataFields.valueY = 'wh_d';
   series2.dataFields.categoryX = 'month';
   series2.name = 'Delivered';
   series2.tooltipText = '{categoryX}: [bold]{valueY}[/]';
   series2.fill = am4core.color('#6c82ff'); // Set the fill color
   series2.stroke = am4core.color('#6c82ff'); // Set the stroke color for the line
   series2.strokeWidth = 2; // Set the stroke width for the line
   series2.tensionX = 0.8; // Adjust tension for smoothness
   series2.strokeWidth = 3; // Increase line width


   // Handle click events on line series
   series2.events.on("hit", function(ev) {
     const columnData = ev.target.dataItem.dataContext;
     props.onMonth_YearChange(columnData.date);
     // console.log(columnData.date); // You can access the associated data here
   });

    let series3 = chart.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = 'net';
    series3.dataFields.categoryX = 'month';
    series3.name = 'Net';
    series3.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series3.columns.template.fill = am4core.color('#202246'); // Set the fill color for the bar
    series3.columns.template.fillOpacity = .9;

     // Handle click events on columns
     series3.columns.template.events.on("hit", function(ev) {
      const columnData = ev.target.dataItem.dataContext;
      props.onMonth_YearChange(columnData.date);
      // console.log(columnData.date); // You can access the associated data here
    });

   // Add legend
   chart.legend = new am4charts.Legend();
   chart.legend.labels.template.fontSize = 10; // Set legend item font size
   chart.legend.markers.template.width = 10; // Set legend marker width
   chart.legend.markers.template.height = 10; // Set legend marker height


    return () => {
      chart.dispose();
    };
  }, [chartData]);

  return  (
    <div className=' d-flex align-items-center justify-content-center' style={{ position: 'relative', height: windowHeight / 2 }}>
    {/* Watermark */}
    <div style={{   position: 'absolute',
    opacity: 0.2, // Adjust opacity as needed
    zIndex: 0, // Ensure watermark is behind other content
    backgroundRepeat: 'repeat',
  }}>
    <img src={`${process.env.PUBLIC_URL}/icons/log.png`} alt="Hostel Icon" width= '100%'/>
    </div>

    {loading && (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
        }}
      >
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )}

    {!loading && (
      <div
        id="bar_graph_yearly"
        className="container-fluid p-1"
        style={{ height: '100%', backgroundColor: '#d1fec5' }}
      />
    )}
  </div>);
  };

export default BarGraph_yearly;
