// Dashboard.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBuilding, faLightbulb, faDoorOpen, faSolarPanel, faLandmark } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import '../style/table.css';
import basepath from '../routes/basepath';


const Dashboard = () => {
  const [roomData, setRoomData] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [startselectedDate, setstartSelectedDate] = useState('');

  const [hostelId, setHostelId] = useState('');
  const [roomNo, setRoomNo] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [responseDatatable, setResponseDatatable] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedHostel, setSelectedHostel] = useState('');
  const [startMonth, setStartMonth] = useState(''); // For the start month
  const [fetchedData, setFetchedData] = useState(null);
  const [cardData, setcardData] = useState([
    { datatype: "NOS", name: 'No of Students', icon: faUsers },
    { datatype: "SC", name: 'Incomer net Kwh', icon: faBuilding },
    { datatype: "RC", name: 'Room consumption Kwh', icon: faLightbulb },
    { datatype: "VC", name: 'No of Vacant rooms', icon: faDoorOpen },
    { datatype: "RI", name: 'Solar Generation Kwh', icon: faSolarPanel },
    { datatype: "SI", name: 'Common Area Kwh', icon: faLandmark }

  ]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.toISOString().slice(0, 7); // Format: YYYY-MM
    // setStartMonth(currentMonth);
    const currentDateStr = currentDate.toISOString().slice(0, 10); // Format: YYYY-MM-DD
    setStartMonth(currentMonth);
    setstartSelectedDate(currentDateStr);

    flatpickr('#startdatepicker', {
      dateFormat: 'Y-m-d',
      defaultDate: currentDate,
      onChange: function (selectedDates, dateStr, instance) {
        console.log('Date selected:', dateStr);
        setstartSelectedDate(dateStr);
      }
    });
  }, []);

  useEffect(() => {
    if (startselectedDate) {
      const apiUrl = basepath + `data/${startselectedDate}`;
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          setResponseData(data);
          const combinedData = cardData.map(card => {
            const apiField = card.datatype === 'NOS' ? 'student_count' :
              card.datatype === 'SC' ? 'incomer' :
                card.datatype === 'RC' ? 'phase_consumption' :
                  card.datatype === 'VC' ? 'room_count_difference' :
                    card.datatype === 'RI' ? 'solar' :
                      card.datatype === 'SI' ? 'commonarea' : '';

            return {
              ...card,
              value: data[0][apiField]
            };
          })
          setcardData(combinedData)
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [startselectedDate]);

  useEffect(() => {

    if (startMonth) {

      const apiUrl = basepath + `monthly-summary/${startMonth}`;
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          setFetchedData(data);

          // Process the data for the table
          const tableData = data.map(item => ({
            // map each item to the appropriate table columns
            hostel_id: item.hostel_id,

            solargeneration: item.solargeneration,
            total_incomer_net: item.total_incomer_net,
            room_kwh: item.room_kwh,
            commonarea: item.commonarea,








            // ... add more columns as needed
          }));

          // Set the processed data to the state
          setResponseDatatable(tableData);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [startMonth]);
  const handleStartMonthChange = (event) => {
    setStartMonth(event.target.value);
  };

  return (
    <div className="container-fluid p-0" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <div className="container-fluid mt-1" style={{ fontFamily: 'Comic Sans MS' }}>
        <h5 style={{ textAlign: "left" }}><b>Billing System</b></h5>
      </div>

      <div className="w-full d-flex p-1 rounded  align-items-center mt-1" style={{ backgroundColor: "#a8d7ff" }}>
        <label htmlFor="datepicker" className="block m-2">
          Select Date:
        </label>
        <div className="relative">
          <input
            type="text"
            id="startdatepicker"
            className="w-full px-4 py-2 pl-10 border rounded"
          />
          <span className="absolute top-0 left-0 flex items-center h-full pl-3">
            <i className="bx bxs-calendar text-gray-500"></i>
          </span>
        </div>
      </div>

      {/* Cards */}
      {/* Cards */}
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {/* Render the first 3 cards in one row */}
        {cardData.slice(0, 3).map((card, index) => (
          <Link to='#' key={index} style={{ textDecoration: 'none', width: '24%' }}>
            <div
              style={{
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                transition: '0.3s',
                borderRadius: '5px',
                backgroundColor: '#d1fEC5',
                margin: '10px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                height: '100px',
              }}
              className="card-box"
            >
              <div
                style={{
                  borderRadius: '5px',
                  marginRight: '10px',
                  padding: '10px',
                }}
                className="card"
              >
                <FontAwesomeIcon icon={card.icon} size="3x" style={{ color: '#333' }} />
              </div>
              <div style={{ padding: '2px 16px' }} className="container">
                <h5>
                  <b style={{ color: 'black' }}>{card.value}</b>
                </h5>
                <h6>
                  <b style={{ color: 'black' }}>{card.name}</b>
                </h6>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Render the other 3 cards in a separate row */}
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {/* Render cards from index 3 to 5 in another row */}
        {cardData.slice(3, 6).map((card, index) => (
          <Link to='#' key={index} style={{ textDecoration: 'none', width: '24%' }}>
            <div
              style={{
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                transition: '0.3s',
                borderRadius: '5px',
                backgroundColor: '#d1fEC5',
                margin: '10px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                height: '100px',
              }}
              className="card-box"
            >
              <div
                style={{
                  borderRadius: '5px',
                  marginRight: '10px',
                  padding: '10px',
                }}
                className="card"
              >
                <FontAwesomeIcon icon={card.icon} size="3x" style={{ color: '#333' }} />
              </div>
              <div style={{ padding: '2px 16px' }} className="container">
                <h5>
                  <b style={{ color: 'black' }}>{card.value}</b>
                </h5>
                <h6>
                  <b style={{ color: 'black' }}>{card.name}</b>
                </h6>
              </div>
            </div>
          </Link>
        ))}
      </div>


      {/* Dropdowns */}

      <input
        type="month"
        id="start"
        name="start"
        min="2018-03"
        value={startMonth}
        onChange={handleStartMonthChange}
        style={{
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '8px',
          width: '20%',
          position: 'relative',
        }}
      />

      {/* Include MonthlySummary component */}


      {/* Heading for the Table */}
      <h5 style={{ textAlign: 'center', marginTop: '20px' }}>Monthly Summary Report</h5>

      {/* Display fetched data if needed */}
      {responseDatatable && (

        <div >
          <table className="custom-table" >
            <thead>
              <tr>
                <th style={{ width: '25%', textAlign: 'left' }}>Hostel</th>
                <th style={{ width: '19%', textAlign: 'right' }}>Solar Generation Kwh</th>
                <th style={{ width: '19%', textAlign: 'right' }}>Total Incomer Net kwh</th>
                <th style={{ width: '19%', textAlign: 'right' }}>Room Kwh</th>
                <th style={{ width: '19%', textAlign: 'right' }}>Common area kwh</th>
              </tr>
            </thead>
            <tbody>
              {responseDatatable.map((row, index) => (
                <tr key={index}>
                  <td style={{ width: '25%', textAlign: 'left' }}>{row.hostel_id}</td>
                  <td style={{ width: '18%', textAlign: 'right' }}>{row.solargeneration}</td>
                  <td style={{ width: '19%', textAlign: 'right' }}>{row.total_incomer_net}</td>
                  <td style={{ width: '19%', textAlign: 'right' }}>{row.room_kwh}</td>
                  <td style={{ width: '19%', textAlign: 'right' }}>{row.commonarea}</td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

      )}
    </div>
  );
};

export default Dashboard;
